import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const RestaurantCard = ({
  currentHappyHourMenuImageUrl,
  currentHappyHourName,
  classes,
}) => (
  <div className={classes.dockContent}>
    <h1>{currentHappyHourName}</h1>
    <img
      src={currentHappyHourMenuImageUrl}
      onError="this.onerror=null;this.src='https://happyhourfinder-menus-prods3.s3-us-west-2.amazonaws.com/happyhourmenus/default.jpg';"
      alt="We are still gathering menu pictures for this happy hour."
      className={classes.imgFormat}
    />
  </div>
);

export default withStyles(styles)(RestaurantCard);
