import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../aws-exports';

// Configure AWS Amplify (probably can move this into an init function on page load along with user auth status check)
Amplify.configure(awsconfig);

/**
 * Register the user
 * @param {object} userInfo
 * @param {function} onSuccess
 * @param {function} onFailure
 * @returns {Promise<void>}
 */
export const signUserIn = async (userInfo, onSuccess, onFailure) => {
  const { email, phoneNumber, password } = userInfo;
  try {
    const response = await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        phone_number: `+1${phoneNumber}`,
      },
    });
    onSuccess(response.user);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Failed to sign user in', error);
    onFailure(error);
  }
};

/**
 * Confirm the user's account and then log them in
 * @param {string} email
 * @param {string} password
 * @param {string} confirmationCode
 * @param {function} onSuccess
 * @param {function} onFailure
 * @returns {Promise<void>}
 */
export const confirmSignUpCode = async (
    email,
    password,
    confirmationCode,
    onSuccess,
    onFailure,
) => {
    try {
        await Auth.confirmSignUp(email, confirmationCode);
        await loginUser({ email, password }, onSuccess, onFailure);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to confirm users code', error);
        onFailure(error);
    }
};

/**
 * Login the user
 * @param {object} userInfo
 * @param {function} onSuccess
 * @param {function} onFailure
 * @returns {Promise<void>}
 */
export const loginUser = async (userInfo, onSuccess, onFailure) => {
  const { email, password } = userInfo;
  try {
    const response = await Auth.signIn(email, password);
    onSuccess(response);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Failed to login user', error);
    onFailure(error);
  }
};

/**
 * Signout the user
 * @param {function} onSuccess
 * @param {function} onFailure
 * @returns {Promise<void>}
 */
export const signUserOut = async (onSuccess, onFailure) => {
  try {
    const response = await Auth.signOut();
    onSuccess(response);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Failed to log user out', error);
    onFailure(error);
  }
};

/**
 * Check the authorization status of the user
 * @param onSuccess
 * @param onFailure
 * @returns {Promise<void>}
 */
export const checkUserStatus = async (onSuccess, onFailure) => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        onSuccess(user);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed user check:', error);
        onFailure(error);
    }
};
