/*
 * This RegEx is used to test for a valid email address
 */
export const EMAIL_REGEX = RegExp(
    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
);
/*
 * The RegEx below requires at least one special, one numeric character,
 * and one capital letter to be present in the string password.
 * The password also needs to be at least six characters long
 */
export const PASSWORD_REGEX_SPECIAL_CHAR = RegExp('[!@#$%^&*(),.?":{}/d|<>]');
export const PASSWORD_REGEX_NUMERIC = RegExp('[0-9]');
export const PASSWORD_REGEX_UPPER_CASE = RegExp('[A-Z]');
export const MINIMUM_PASSWORD_LENGTH = 6;

export const EMAIL_PLACEHOLDER = 'Email';
export const PHONE_NUMBER_PLACEHOLDER = 'Phone Number';

/*
 * RegEx for phone number. This will match on the following:
 * (123) 456-7890
 * (123)456-7890
 * 123-456-7890
 * 123.456.7890
 * 1234567890
 * +31636363634
 * 075-63546725
 */
export const PHONE_NUMBER_REGEX = RegExp(
    '[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}',
);

export const AUTH_STATUS_PENDING = 'pending';
export const AUTH_SIGN_UP_SUCCEEDED = 'signUpSucceeded';
export const AUTH_SIGN_UP_FAILED = 'signUpFailed';
export const AUTH_SIGN_UP_CONFIRMATION_SUCCEEDED = 'signUpConfirmationSucceeded';
export const AUTH_SIGN_UP_CONFIRMATION_FAILED = 'signUpConfirmationFailed';
export const AUTH_LOGIN_SUCCEEDED = 'loginSucceeded';
export const AUTH_LOGIN_FAILED = 'loginFailed';
export const AUTH_SIGN_OUT_SUCCEEDED = 'signOutSucceeded';
export const AUTH_SIGN_OUT_FAILED = 'signOutFailed';
