export default () => ({
  wrapper: {
    position: 'fixed',
    width: 0,
    height: 0,
    top: 0,
    left: 0,
  },
  dim: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 0,
    background: 'rgba(70, 6, 6, 0.6)',
    opacity: 1,
  },
  dimAppear: {
    opacity: 0,
  },
  dimTransparent: {
    pointerEvents: 'none',
  },
  dimHidden: {
    opacity: 0,
  },
  dock: {
    position: 'fixed',
    zIndex: 1,
    boxShadow: '0 0 4px rgba(0, 0, 0, 0.6)',
    background: 'white',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
  dockHidden: {
    opacity: 0,
  },
  dockResizing: {
    transition: 'none',
  },
  dockContent: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
  resizer: {
    position: 'absolute',
    zIndex: 2,
    opacity: 0,
  },
});
