import { parseErrorMessageFromCognito } from '../util/utils';
import { authStore as auth } from '../auth/reducer';
import {
  AUTH_SIGN_UP_CONFIRMATION_FAILED,
  AUTH_SIGN_UP_CONFIRMATION_SUCCEEDED,
  AUTH_SIGN_UP_FAILED,
  AUTH_SIGN_UP_SUCCEEDED,
  AUTH_STATUS_PENDING,
  AUTH_LOGIN_FAILED,
  AUTH_LOGIN_SUCCEEDED,
  AUTH_SIGN_OUT_SUCCEEDED,
  AUTH_SIGN_OUT_FAILED,
} from '../auth/constants';

/*
 * Define the shape of the state management object
 */
export const INITIAL_STATE = {
  stores: {
    auth,
  },
};

export const rootReducer = (state, action) => {
    switch (action.type) {
        case AUTH_SIGN_OUT_SUCCEEDED: {
            return {
                ...INITIAL_STATE,
            };
        }
        case AUTH_SIGN_UP_SUCCEEDED: {
            const { rawSignInData } = action;
            return {
                ...state,
                stores: {
                    auth: {
                        rawSignInData,
                        confirmedAccount: AUTH_STATUS_PENDING,
                        errorMessage: null,
                        error: null,
                    },
                },
            };
        }
        case AUTH_LOGIN_SUCCEEDED: // Intentional Fallthrough
        case AUTH_SIGN_UP_CONFIRMATION_SUCCEEDED: {
            const { rawSignInData } = action;
            return {
                ...state,
                stores: {
                    ...state.auth,
                    auth: {
                        ...state.stores.auth,
                        rawSignInData,
                        confirmedAccount: true,
                        errorMessage: null,
                        error: null,
                    },
                },
            };
        }
        case AUTH_SIGN_OUT_FAILED: // Intentional Fallthrough
        case AUTH_LOGIN_FAILED: // Intentional Fallthrough
        case AUTH_SIGN_UP_FAILED: // Intentional Fallthrough
        case AUTH_SIGN_UP_CONFIRMATION_FAILED: {
            const { error } = action;
            return {
                ...state,
                stores: {
                    auth: {
                        ...state.stores.auth,
                        error,
                        errorMessage: parseErrorMessageFromCognito(error),
                    },
                },
            };
        }
        default:
            return state;
    }
};
