import React, { Fragment } from 'react';
import './App.css';
import MapContainer from './map/MainMap';
import RestaurantCard from './restaurant/RestaurantCard';
import NavBar from './navbar/NavBar/index';
import Dock from './dock/Dock';
import happyHourData from './util/HappyHours';
import { IoMdCloseCircle as CloseCircle } from 'react-icons/io';
import { checkUserStatus } from './store/api';
import ReactGA from 'react-ga';


// THEME
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import theme from './theme/styles';

import config from './config/config'

const styles = {
    remove: {
        position: 'absolute',
        zIndex: 1,
        right: '10px',
        top: '10px',
        width: '5%',
        height: '5%',
        minHeight: '25px',
        minWidth: '25px',
        cursor: 'pointer',
    },
};
ReactGA.initialize('G-R9R0SMSJJC');

export class AppContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userLocation: { lat: null, lng: null },
            markers: null,
            happyHours: null,
            showDock: false,
            loadedHappyHours: false,
        };
    }

    componentDidMount() {
        const {
            handlers: {
                authHandlers: {
                    handleInitializationSuccess,
                    handleInitializationFailure,
                },
            },
        } = this.props;
        checkUserStatus(
            handleInitializationSuccess,
            handleInitializationFailure,
        );
    }

    updateUserLocation = (userCoords) => {
        happyHourData(userCoords).then(
            (result) => {
                this.setState({
                    userLocation: userCoords,
                    happyHours: result,
                    loaded: true,
                });
            },
            /*
             * Note: it's important to handle errors here
             * instead of a catch() block so that we don't swallow
             * exceptions from actual bugs in components.
             */
            (error) => {
                this.setState({
                    loaded: true,
                    error,
                });
            },
        );
    };

    setCurrentHappyHour = (happyHourObject) => {
        this.setState({ currentHappyHourName: happyHourObject.EstablishmentName });
        this.setState({ currentHappyHourStartTime: happyHourObject.open });
        this.setState({ currentHappyHourFinishTime: happyHourObject.close });
        this.setState({
            currentHappyHourMenuImageUrl:  `https://${config.happyHourS3}.s3-${config.region}.amazonaws.com/${happyHourObject.S3PrimaryMenu}`,
        });
    };

    updateHappyHourVisibility = (happyHourId, isVisible) => {
        const updatedHappyHourState = this.state.happyHours;
        for (let i = 0; i < updatedHappyHourState.length; i++) {
            if (updatedHappyHourState[i].Address === happyHourId) {
                this.setCurrentHappyHour(updatedHappyHourState[i]);
                updatedHappyHourState[i].visible = isVisible;
                break;
            }
        }
        this.setState({
            happyHours: updatedHappyHourState,
            showDock: isVisible,
        });
    };

    isDockVisible = () => {
        for (let i = 0; i < this.state.happyHours.len; i++) {
            if (this.state.happyHours[i].visible === true) {
                return true;
            }
        }
        return false;
    };

    removeVisiblieHappyHours = () => {
        const happyHourId = this.getVisibleHappyHour;
        this.updateHappyHourVisibility(happyHourId, false);
    };

    App() {
        return (
            <Fragment>
                <MuiThemeProvider theme={theme}>
                    <NavBar {...this.props} />
                </MuiThemeProvider>
                <MapContainer
                    userLocation={this.state.userLocation}
                    updateUserLocation={this.updateUserLocation}
                    happyHours={this.state.happyHours}
                    updateHappyHourVisibility={this.updateHappyHourVisibility}
                />
                <Dock position="bottom" isVisible={this.state.showDock}>
                    <CloseCircle
                        onClick={this.removeVisiblieHappyHours}
                        style={styles.remove}
                    />
                    <RestaurantCard
                        currentHappyHourName={this.state.currentHappyHourName}
                        currentHappyHourStartTime={
                            this.state.currentHappyHourStartTime
                        }
                        currentHappyHourFinishTime={
                            this.state.currentHappyHourFinishTime
                        }
                        currentHappyHourMenuImageUrl={
                            this.state.currentHappyHourMenuImageUrl
                        }
                    />
                </Dock>
            </Fragment>
        );
    }

    render() {
        return this.App();
    }
}

export default AppContainer;
