export default () => ({
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    form: {
        flexDirection: 'column',
        backgroundColor: 'white',
        display: 'flex',
        padding: '15px',
        width: '275px',
        outline: 'none',
        borderRadius: '8px',
    },
    textInput: {
        margin: '10px',
    },
    button: {
        margin: '5px',
    },
    list: {
        paddingLeft: '20px',
    },
});
