import config from '../config/config';

function happyHourData(userCoords) {
  if (userCoords) {
    const { lat } = userCoords;
    const { lng } = userCoords;
    /*
         * TODO: format this response on the server-side to be more friendly
         * TODO: Change the url to fetch against based on the stage of the website. Right now this is set to my dev API-G
         */
    // console.log(config)
    const url = `${config.apiGateway}/Prod/v1/query_happy_hours_lat_long/${lat}/${lng}`
    // console.log(url);
    return fetch(
      url,
    ).then((response) => response.json());
  }

  // [{"S3AllMenuPrefix": "S3_PREFIX", "UpdatedEpoch": "1594953538", "Address": "1357 Franklin St, Denver Co 80218", "InaccurateCount": "0", "UpdatedHumanTime": "07/17/2020 02:38:58", "SmallGeocode": "9xj65", "Longitude": "-104.9687259", "Latitude": "39.7379817", "EstablishmentName": "Nicks House", "S3PrimaryMenu": "S3_KEY", "LargeGeocode": "9xj6", "Rating": "0"}, {"S3AllMenuPrefix": "S3_PREFIX", "UpdatedEpoch": "1594497519", "Address": "1358 Franklin St, Denver Co 80218", "InaccurateCount": "0", "UpdatedHumanTime": "07/11/2020 19:58:39", "SmallGeocode": "9xj65", "Longitude": "-104.9687259", "Latitude": "39.7379817", "EstablishmentName": "Nicks House", "S3PrimaryMenu": "S3_KEY", "LargeGeocode": "9xj6", "Rating": "0"}]

}

export default happyHourData;
