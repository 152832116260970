import React, { createContext, useReducer } from 'react';
import { INITIAL_STATE, rootReducer } from './reducer';
import {
  AUTH_LOGIN_SUCCEEDED,
  AUTH_LOGIN_FAILED,
  AUTH_SIGN_UP_CONFIRMATION_FAILED,
  AUTH_SIGN_UP_CONFIRMATION_SUCCEEDED,
  AUTH_SIGN_UP_FAILED,
  AUTH_SIGN_UP_SUCCEEDED,
  AUTH_SIGN_OUT_FAILED,
  AUTH_SIGN_OUT_SUCCEEDED,
} from '../auth/constants';

// Initialize the Global Context
const GlobalContext = createContext();

const GlobalContextProvider = ({ authStore, ...props }) => {
    const [state, dispatch] = useReducer(rootReducer, INITIAL_STATE);
    return (
        <GlobalContext.Provider
            value={{
                ...state,
                // TODO Move handlers into their own files
                handlers: {
                    authHandlers: {
                        handleSignInSuccess: (rawSignInData) =>
                            dispatch({
                                type: AUTH_SIGN_UP_SUCCEEDED,
                                rawSignInData,
                            }),
                        handleSignInFailed: (error) =>
                            dispatch({ type: AUTH_SIGN_UP_FAILED, error }),
                        handleSignInConfirmationSuccess: (rawSignInData) =>
                            dispatch({
                                type: AUTH_SIGN_UP_CONFIRMATION_SUCCEEDED,
                                rawSignInData,
                            }),
                        handleSignInConfirmationFailed: (error) =>
                            dispatch({
                                type: AUTH_SIGN_UP_CONFIRMATION_FAILED,
                                error,
                            }),
                        handleLoginSuccess: (rawSignInData) =>
                            dispatch({
                                type: AUTH_LOGIN_SUCCEEDED,
                                rawSignInData,
                            }),
                        handleLoginFailed: (error) =>
                            dispatch({ type: AUTH_LOGIN_FAILED, error }),
                        handleSignOutSuccess: () =>
                            dispatch({ type: AUTH_SIGN_OUT_SUCCEEDED }),
                        handleSignOutFailed: (error) =>
                            dispatch({ type: AUTH_SIGN_OUT_FAILED, error }),
                        handleInitializationSuccess: (rawSignInData) =>
                            dispatch({
                                type: AUTH_LOGIN_SUCCEEDED,
                                rawSignInData,
                            }),
                        handleInitializationFailure: (error) =>
                            dispatch({ type: AUTH_LOGIN_FAILED, error }),
                    },
                },
            }}
        >
            {props.children}
        </GlobalContext.Provider>
    );
};

export const CreateGlobalStore = ({ AppContainer }) => (
  <GlobalContextProvider value={INITIAL_STATE}>
    <GlobalContext.Consumer>
      {(props) => <AppContainer {...props} />}
    </GlobalContext.Consumer>
  </GlobalContextProvider>
);
