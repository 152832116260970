import React from 'react';
import SignIn from '../../auth/SignIn';
import Login from '../../auth/Login';
import UploadHappyHour from '../../util/UploadHappyHour'
import { signUserOut } from '../../store/api';

// Material UI Common Components
import {
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Menu,
    MenuItem,
} from '@material-ui/core';

// Icons
import { Menu as MenuIcon, AccountCircle } from '@material-ui/icons';

// Styles
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const Navbar = ({ classes, handlers, ...restProps }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [signInModalVisible, setSignInModalVisible] = React.useState(false);
    const [loginModalVisible, setLoginModalVisible] = React.useState(false);
    const [uploadModalVisible, setUploadModalVisible] = React.useState(false);
    const open = Boolean(anchorEl);

    // TODO next task will invovle moving this 'selectors' to another file
    const { authHandlers } = handlers;
    const {
        stores: { auth },
    } = restProps;
    const userName = auth?.rawSignInData?.attributes?.email || null;

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSignOut = () => {
        signUserOut(
            authHandlers.handleSignOutSuccess,
            authHandlers.handleSignInSuccess,
        );
    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    {userName ? (
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="menu"
                        >
                            <MenuIcon />
                        </IconButton>
                    ) : null}

                    <Typography variant="h6" className={classes.title}>
                        HappyHourFinder(BETA)
                    </Typography>
                    {userName ? (
                        <Typography variant="h6" className={classes.title}>
                            Welcome {userName}
                        </Typography>
                    ) : null}
                    <div>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleClose}
                        >
                            {!userName ? (
                                [
                                    //TODO: uncomment when account/login stuff is ready
                                    // <MenuItem
                                    //     key={1}
                                    //     onClick={() =>
                                    //         setSignInModalVisible(true)
                                    //     }
                                    // >
                                    //     Sign Up
                                    // </MenuItem>,
                                    // <MenuItem
                                    //     key={2}
                                    //     onClick={() =>
                                    //         setLoginModalVisible(true)
                                    //     }
                                    // >
                                    //     Login
                                    // </MenuItem>,
                                    <MenuItem
                                        key={3}
                                        onClick={() =>
                                          setUploadModalVisible(true)
                                        }
                                    >
                                        Upload Happy Hour
                                    </MenuItem>,
                                ]
                            ) : (
                                <MenuItem onClick={handleSignOut}>
                                    Logout
                                </MenuItem>
                            )}
                        </Menu>
                        <SignIn
                            isModalVisible={signInModalVisible}
                            onClose={() => setSignInModalVisible(false)}
                            authHandlers={authHandlers}
                            authStore={auth}
                        />
                        <Login
                            isModalVisible={loginModalVisible}
                            onClose={() => setLoginModalVisible(false)}
                            authHandlers={authHandlers}
                            authStore={auth}
                        />
                      <UploadHappyHour
                            isModalVisible={uploadModalVisible}
                            onClose={() => setUploadModalVisible(false)}
                        />

                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default withStyles(styles)(Navbar);
