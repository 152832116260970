export default () => ({
  dockContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  imgFormat: {
    width: 'auto',
    maxWidth: '80%',
    minHeight: '40%',
    maxHeight: '80%',
    height: 'auto',
  },
});
