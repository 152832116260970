import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';

// Material UI
import {
  Button, Modal, Typography, TextField,
} from '@material-ui/core';

// Styles
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

import { signUserIn, confirmSignUpCode } from '../../store/api';

// Constants
import {
    AUTH_STATUS_PENDING,
    PASSWORD_REGEX_NUMERIC,
    PHONE_NUMBER_REGEX,
    EMAIL_REGEX,
    PASSWORD_REGEX_SPECIAL_CHAR,
    EMAIL_PLACEHOLDER,
    PHONE_NUMBER_PLACEHOLDER,
    PASSWORD_REGEX_UPPER_CASE,
    MINIMUM_PASSWORD_LENGTH,
} from '../../auth/constants';

import { noop } from '../../util/utils';

const SignIn = ({
  classes,
  isModalVisible,
  onClose,
  authHandlers,
  authStore,
}) => {
    const {
        handleSignInSuccess,
        handleSignInFailed,
        handleSignInConfirmationSuccess,
        handleSignInConfirmationFailed,
    } = authHandlers;
    const { confirmedAccount, errorMessage: apiErrorMessage } = authStore;
    const shouldShowConfirmation = confirmedAccount === AUTH_STATUS_PENDING;

    const [email, setEmail] = useState(EMAIL_PLACEHOLDER);
    const [phoneNumber, setPhoneNumber] = useState(PHONE_NUMBER_PLACEHOLDER);
    const [password, setPassword] = useState('');
    const [confirmationCode, setConfirmationCode] = useState('');

  // Close the modal once the account has been confirmed
  useEffect(() => {
    if (confirmedAccount === true) {
      onClose && onClose();
    }
  }, [confirmedAccount, onClose]);

  /**
     * Collect the user's input values and call the API function to sign them up
     */
  const signInRequested = () => {
    const userInfo = {
      email,
      phoneNumber,
      password,
    };
    signUserIn(userInfo, handleSignInSuccess, handleSignInFailed);
  };

  /**
     * Take the user given code and confirm their account
     */
    const confirmSignUpCodeRequested = () => {
        confirmSignUpCode(
            email,
            password,
            confirmationCode,
            handleSignInConfirmationSuccess,
            handleSignInConfirmationFailed,
        );
    };

    /**
     * Test user input email against RegEx requirements
     * @returns {boolean}
     */
    const isEmailValid = () => EMAIL_REGEX.test(email);

    /**
     * Test user input phone number against RegEx requirements
     * @returns {boolean}
     */
    const isPhoneNumberValid = () => PHONE_NUMBER_REGEX.test(phoneNumber);

    /**
     * Test user input password against RegEx requirements
     * @returns {boolean}
     */
    const isPasswordValid = () =>
        password?.length >= MINIMUM_PASSWORD_LENGTH &&
        PASSWORD_REGEX_SPECIAL_CHAR.test(password) &&
        PASSWORD_REGEX_NUMERIC.test(password) &&
        PASSWORD_REGEX_UPPER_CASE.test(password);

    /**
     * Below functions return the various alerts to the user depending on what input needs to be changed
     * @returns {*}
     */
    const renderEmailAlert = () => {
        return !isEmailValid() ? (
            <Alert severity="error">Please enter a valid email address.</Alert>
        ) : null;
    };

    const renderPhoneNumberAlert = () => {
        return !isPhoneNumberValid() ? (
            <Alert severity="error">Please enter a valid phone number.</Alert>
        ) : null;
    };

    const renderPasswordAlert = () => {
        return isPasswordValid() ? (
            <Alert severity="success">Your password is secure.</Alert>
        ) : (
            <Alert severity="error">
                Your password must contain the following:
                <ul className={classes.list}>
                    <li>One special character</li>
                    <li>One uppercase letter</li>
                    <li>One numeric character</li>
                    <li>At least 6 character long</li>
                </ul>
            </Alert>
        );
    };

    const renderGeneralAlert = () =>
        apiErrorMessage ? (
            <Alert severity="error">{apiErrorMessage}</Alert>
        ) : null;

    /**
     * Below functions serve as triggers for when to show user friendly alerts
     * @returns {*}
     */
    const shouldRenderEmailAlert = () =>
        email &&
        String(email)
            .trim()
            .toLowerCase() !== EMAIL_PLACEHOLDER.toLowerCase() &&
        renderEmailAlert();

    const shouldRenderPhoneNumberAlert = () =>
        phoneNumber &&
        String(phoneNumber)
            .trim()
            .toLowerCase() !== PHONE_NUMBER_PLACEHOLDER.toLowerCase() &&
        renderPhoneNumberAlert();

    const shouldRenderPasswordAlert = () => password && renderPasswordAlert();

  return (
    <Modal
      open={isModalVisible}
      onClose={onClose}
      aria-labelledby="sign-in-modal"
      aria-describedby="allows-users-to-register"
      className={classes.modal}
    >
      <form className={classes.form}>
        {shouldShowConfirmation ? (
          <>
            <Typography variant="h6">
              Check your email for a code!
            </Typography>
            <TextField
              required
              id="confirmation-input"
              variant="outlined"
              placeholder="Confirmation Code"
              value={confirmationCode}
              onChange={({ target: { value } }) => setConfirmationCode(value)}
              className={classes.textInput}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={confirmSignUpCodeRequested}
              disabled={!confirmationCode}
            >
              Register
            </Button>
          </>
        ) : (
          <>
            <Typography variant="h4">Sign Up!</Typography>
            <TextField
              required
              id="email-input"
              label="Required"
              variant="outlined"
              value={email}
              onChange={({ target: { value } }) => setEmail(value)}
              className={classes.textInput}
            />
            <TextField
              required
              id="phone-number-input"
              label="Required"
              variant="outlined"
              value={phoneNumber}
              onChange={({ target: { value } }) => setPhoneNumber(value)}
              className={classes.textInput}
            />
            <TextField
              id="password-input"
              label="Password"
              type="password"
              autoComplete="current-password"
              variant="outlined"
              value={password}
              onChange={({ target: { value } }) => setPassword(value)}
              className={classes.textInput}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={signInRequested}
              disabled={!email || !phoneNumber || !password}
            >
              Register
            </Button>
          </>
        )}
      </form>
    </Modal>
  );
};

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
  isModalVisible: PropTypes.bool,
  onClose: PropTypes.func,
  authHandlers: PropTypes.object.isRequired,
  authStore: PropTypes.object.isRequired,
};

SignIn.defaultProps = {
  isModalVisible: false,
  onClose: noop,
};

export default withStyles(styles)(SignIn);
