// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-west-2',
  aws_cognito_region: 'us-west-2',
  aws_user_pools_id: 'us-west-2_VaGDZcXLm',
  aws_user_pools_web_client_id: '5fbbiitn244sihpokgbgojv50e',
  oauth: {},
};

export default awsmobile;
