import React from 'react';
import { GoogleApiWrapper, Marker, Map } from 'google-maps-react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles'

export class MapContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userLocation: this.props.userLocation,
            loading: true,
        };
    }

    componentDidMount() {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                this.setState({
                    userLocation: { lat: latitude, lng: longitude },
                    loading: false,
                });
                this.props.updateUserLocation(this.state.userLocation);
            },
            () => {
                this.setState({ loading: false });
            },
        );
        // Default location to denver if the user has location disabled
        if (this.state.userLocation.lat == null || this.state.userLocation.lng == null) {
            console.log("Using default location of Denver.")
            this.setState({
                userLocation: { lat: 39.742043, lng: -104.991531 },
                loading: false,
            }, () => {
                this.props.updateUserLocation(this.state.userLocation);
                }
            );
        }
    }

    generateMarkers = () => {
        if (this.props.happyHours) {
            // on the first load this might be null
            return this.props.happyHours.map((item) => (
                <Marker
                    key={`${item.EstablishmentName}${item.Address}`}
                    title={item.EstablishmentName}
                    name={item.EstablishmentName}
                    position={{ lat: item.Latitude, lng: item.Longitude }}
                    id={item.Address}
                    onClick={() =>
                        this.props.updateHappyHourVisibility(item.Address, true)
                    }
                />
            ));
        }
    };

    centerMoved(map) {
        // TODO: do some math below so that we only update happy hours if the user
        // drags the map a significant distance (1+ miles?).
        this.setState({
            userLocation: { lat: map.center.lat(), lng: map.center.lng() },
            loading: false,
        }, () => {
            this.props.updateUserLocation(this.state.userLocation);
            }
        );
      }

    render() {
        const { loading, userLocation } = this.state;
        const { google } = this.props;
        if (loading) {
            return null;
        }

        return (
            <React.Fragment>
                <Map 
                    google={google} 
                    initialCenter={userLocation} 
                    zoom={10} 
                    loadingMessage={'Loading'} 
                    onDragend={(t, map) => this.centerMoved(map)}
                    resetBoundsOnResize = {true}
                    style={styles().mapStyle} 
                    containerStyle={styles().containerStyle}
                    streetViewControl={false}
                    scaleControl={false}
                    mapTypeControl={false}
                    fullscreenControl={false}
                    zoomControl={false}
                    >
                    {this.generateMarkers()}
                </Map>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
})(MapContainer))
