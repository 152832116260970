import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Material UI
import { Button, Modal, Typography, TextField } from '@material-ui/core';

// Styles
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';

import { loginUser } from '../../store/api';
import { noop } from '../../util/utils';
import { Alert } from '@material-ui/lab';

const Login = ({
    classes,
    isModalVisible,
    onClose,
    authHandlers,
    authStore,
}) => {
    const { handleLoginSuccess, handleLoginFailed } = authHandlers;
    const { rawSignInData, errorMessage: apiErrorMessage } = authStore;

    const [email, setEmail] = useState('Email');
    const [password, setPassword] = useState('');

    useEffect(() => {
        if (rawSignInData?.username) {
            onClose && onClose();
        }
    }, [rawSignInData, onClose]);

    const loginRequested = () => {
        const userInfo = {
            email,
            password,
        };

        loginUser(userInfo, handleLoginSuccess, handleLoginFailed);
    };

    const renderGeneralAlert = () =>
        apiErrorMessage ? (
            <Alert severity="error">{apiErrorMessage}</Alert>
        ) : null;

    return (
        <Modal
            open={isModalVisible}
            onClose={onClose}
            aria-labelledby="login-modal"
            aria-describedby="allows-users-to-login"
            className={classes.modal}
        >
            <form className={classes.form}>
                <Typography variant="h4">Login!</Typography>
                <TextField
                    required
                    id="email-input"
                    label="Required"
                    variant="outlined"
                    value={email}
                    onChange={({ target: { value } }) => setEmail(value)}
                    className={classes.textInput}
                />
                <TextField
                    id="password-input"
                    label="Password"
                    type="password"
                    autoComplete="current-password"
                    variant="outlined"
                    value={password}
                    onChange={({ target: { value } }) => setPassword(value)}
                    className={classes.textInput}
                />
                {renderGeneralAlert()}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={loginRequested}
                    disabled={!email || !password}
                >
                    Login
                </Button>
            </form>
        </Modal>
    );
};

Login.propTypes = {
    classes: PropTypes.object.isRequired,
    isModalVisible: PropTypes.bool,
    onClose: PropTypes.func,
    authHandlers: PropTypes.object.isRequired,
    authStore: PropTypes.object.isRequired,
};

Login.defaultProps = {
    isModalVisible: false,
    onClose: noop,
};

export default withStyles(styles)(Login);
