export default () => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    flexDirection: 'column',
    backgroundColor: 'white',
    display: 'flex',
    padding: '10px',
    width: '350px',
  },
  textInput: {
    margin: '10px',
  },
  loader: {
    border: '16px solid #f3f3f3',
    borderradius: '50%',
    bordertop: '16px solid #3498db',
    width: '120px',
    height: '120px',
    animation: 'spin 2s linear infinite',
  },

});
