import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#363636',
    },
    secondary: {
      light: '#0066ff',
      main: '#FE6B8B',
      contrastText: '#000000',
    },
    error: red,
  },
  status: {
    danger: 'orange',
  },
});

export default theme;
