import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { Spinner } from 'react-bootstrap';

/*
 * import AutoComplete from 'react-google-autocomplete';
 * import PlacesAutocomplete from 'react-places-autocomplete';
 */

// Material UI
import {
  Button, Modal, Typography, TextField,
} from '@material-ui/core';

// Styles
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

import axios from 'axios';
import config from '../../config/config';

/*
 * TODO items:
 *    - Add form validation
 */
const UploadHappyHour = ({
  classes,
  isModalVisible,
  onClose,
}) => {

  const [resturantInfo, setResturantInfo] = useState('resturantInfo');
  const [file, setFile] = useState('file');
  const [isUploading, setIsUploading] = React.useState(false);

  const update = (event) => {
    console.log(event);
  };

  const uploadRequested = async () => {
    try {
      if (!file) {
        throw new Error('Select a file first!');
      }
      const formData = new FormData();
      formData.append('googlePlacesCode', resturantInfo.value.place_id);
      formData.append('file', file[0]);

      setIsUploading(true);

      const url = `${config.apiGateway}/Prod/v1/uploadhappyhour`;

      await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert("Thanks! HappyHour Uploaded.")
      // isModalVisible = false;
    } catch (error) {
      alert("Unable to upload happy hour. Try again later!")
    }
    setIsUploading(false);
    onClose();
  };

  const UploadLoader = () => (
    <div>
      <i class="fa fa-spinner fa-spin"></i>Loading
    </div>
  );

  return (
    <div>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
      <Modal
        open={isModalVisible}
        onClose={onClose}
        aria-labelledby="upload-modal"
        aria-describedby="allows-users-to-upload"
        className={classes.modal}
      >
        <form className={classes.form}>
          <Typography variant="h5">Upload Happy Hour:</Typography>
          <Typography variant="h6">Resturant Name:</Typography>
          <GooglePlacesAutocomplete
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            selectProps={{
              resturantInfo,
              onChange: setResturantInfo,
            }}
            autocompletionRequest={{
              componentRestrictions: {
                country: ['us'],
              },
            }}
          />
        <Typography variant="h6">Happy Hour Photo:</Typography>
          <input type="file" onChange={event => setFile(event.target.files)} />
          <Button
            variant="contained"
            color="primary"
            onClick={uploadRequested}
            disabled={!resturantInfo}
          >
            { isUploading ? <UploadLoader /> : "Upload" }
          </Button>
        </form>
      </Modal>
      { isUploading ? <UploadLoader /> : null }
    </div>
  );
};

UploadHappyHour.propTypes = {
  classes: PropTypes.object.isRequired,
  uploadFormVisibile: PropTypes.bool,
  onClose: PropTypes.func,
  authHandlers: PropTypes.object.isRequired,
  authStore: PropTypes.object.isRequired,
};

UploadHappyHour.defaultProps = {
  uploadFormVisible: false,
  // onClose: noop,
};

export default withStyles(styles)(UploadHappyHour);
