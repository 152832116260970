export default () => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    flexDirection: 'column',
    backgroundColor: 'white',
    display: 'flex',
    padding: '10px',
    width: '275px',
  },
  textInput: {
    margin: '10px',
  },
});
