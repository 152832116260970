// Inspired by https://serverless-stack.com/chapters/environments-in-create-react-app.html

const nickDev = new function() {
	this.account = "817942345782"
	this.region = "us-west-2"
	this.happyHourS3 = `happyhourfinder-menus-s3-dev-${this.region}-${this.account}`
	this.apiGateway = `https://jpog7huqve.execute-api.${this.region}.amazonaws.com`
	this.googlePlacesApi=process.env.REACT_APP_GOOGLE_MAPS_API_KEY
};

const prod = new function() {
	this.account = "354884208693"
	this.region = "us-west-2"
	this.happyHourS3 = `happyhourfinder-menus-s3-prod-${this.region}-${this.account}`
	this.apiGateway = `https://pc2z9nexk9.execute-api.${this.region}.amazonaws.com`
	this.googlePlacesApi=process.env.REACT_APP_GOOGLE_MAPS_API_KEY
};

const config = process.env.REACT_APP_STAGE === 'production'
? prod
: nickDev;

export default config;
