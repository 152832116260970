export default () => ({
    Map: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    mapStyle: {
      maxWidth: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "hidden"
    },
    containerStyle: {
      maxWidth: "100%",
      height: "91%"
    },
    imgFormat: {
      width: 'auto',
      height: '80%',
    },
  });
