/*
 * This file contains general custom helper functions that are used throughout the app
 */

// Simple common noop function
const noop = () => {};

/**
 *
 * @param {object} error raw object from Amazon Cognito API
 * @returns {null|string}
 */
const parseErrorMessageFromCognito = (error) => {
    let errorMessage = null;
    if (
        error &&
        typeof error === 'object' &&
        Object.prototype.hasOwnProperty.call(error, 'message')
    ) {
        errorMessage = `${error.message}`;
    }
    return errorMessage;
};

export { noop, parseErrorMessageFromCognito };
