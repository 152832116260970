// Taken from https://github.com/alexkuz/react-dock/blob/master/src/autoprefix.js

const vendorSpecificProperties = [
  'animation',
  'animationDelay',
  'animationDirection',
  'animationDuration',
  'animationFillMode',
  'animationIterationCount',
  'animationName',
  'animationPlayState',
  'animationTimingFunction',
  'appearance',
  'backfaceVisibility',
  'backgroundClip',
  'borderImage',
  'borderImageSlice',
  'boxSizing',
  'boxShadow',
  'contentColumns',
  'transform',
  'transformOrigin',
  'transformStyle',
  'transition',
  'transitionDelay',
  'transitionDuration',
  'transitionProperty',
  'transitionTimingFunction',
  'perspective',
  'perspectiveOrigin',
  'userSelect',
];

const prefixes = ['Moz', 'Webkit', 'ms', 'O'];

const prefixProp = (key, value) => prefixes.reduce(
  (obj, pre) => (
    // eslint-disable-next-line no-mixed-spaces-and-tabs, no-sequences, no-return-assign
    (obj[pre + key[0].toUpperCase() + key.substr(1)] = value), obj
  ),
  {},
);

const autoPrefix = (style) => Object.keys(style).reduce(
  (obj, key) => (vendorSpecificProperties.indexOf(key) !== -1
    ? {
      ...obj,
      ...prefixProp(key, style[key]),
    }
    : obj),
  style,
);

export default autoPrefix;
